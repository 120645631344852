import { MembershipOfferPage } from '@aaa/emember/store-membership-offers';
import { FieldComponent, FieldComponentType } from '../../modules/render';

export const membershipOffers = (page: MembershipOfferPage): FieldComponent[] => {
  const fields: FieldComponent[] = [];

  switch (page) {
    case 'offers':
      {
        fields.push({
          type: FieldComponentType.HEADER2,
          props: { text: 'Join AAA Today', cls: 'ava-text-xxl ava-text-bolder ava-pb-2 ava-mb-2' },
        });

        const texts = [
          "Legendary 24/7 Roadside Assistance in any car — whether you're the driver or a passenger",
          'Includes mobile battery service, emergency fuel delivery, lockout service',
          'Discounts on hotels, car rentals, entertainment, auto repair, and more',
          'Travel and vacation planning services with exclusive perks for members',
        ];
        for (const text of texts) {
          const section: FieldComponent = {
            type: FieldComponentType.SECTION,
            props: { cls: 'ava-row ava-gap-1' },
            fieldGroup: [
              { type: FieldComponentType.ICON, props: { icon: 'icons:checkmark', cls: 'ava-icon-red' } },
              {
                type: FieldComponentType.PARAGRAPH,
                props: { text: text },
              },
            ],
          };

          fields.push(section);
        }
      }
      break;
    case 'gift-offers':
      {
        fields.push(
          {
            type: FieldComponentType.PARAGRAPH,
            props: { text: 'AAA Gift', cls: 'ava-text-xxl ava-bold-400 ava-pb-0 ava-mb-0' },
          },
          {
            type: FieldComponentType.PARAGRAPH,
            props: { text: 'Membership', cls: 'ava-text-xxl ava-bold-400 ava-mb-1' },
          },
          {
            type: FieldComponentType.PARAGRAPH,
            props: {
              text: 'Give the Gift of Peace of Mind',
              cls: 'ava-congrats-description ava-text-xl ava-pb-0 ava-mb-0',
            },
          },
          {
            type: FieldComponentType.PARAGRAPH,
            props: {
              text: 'with 24/7 Emergency Roadside Assistance Coverage, Provided 365 Days a Year.',
              cls: 'ava-congrats-description ava-text-xl ava-pb-0 ava-mb-0',
            },
          },
        );
      }
      break;
  }

  return fields;
};

import { createReducer, on } from '@ngrx/store';
import { JoinActions } from './join.actions';
import { RequestStatus } from '../../../types/request-status';
import { JoinState } from './join.models';

export const initialStateJoinState: JoinState = {
  formActiveStep: '',
  formValues: {},
  validationRequestStatus: RequestStatus.UNSENT,
  validationError: null,
  executionData: null,
  executionRequestStatus: RequestStatus.UNSENT,
  executionError: null,
  totalCost: 0,
  page: '',
  members: [],
};

export const joinReducer = createReducer(
  initialStateJoinState,
  on(JoinActions.setFormActiveStep, (state, { activeStep }) => ({
    ...state,
    formActiveStep: activeStep,
  })),
  on(JoinActions.changedQueryMembership, (state, { membershipLevel }) => ({
    ...state,
    formValues: {
      ...state.formValues,
      memberInfo: {
        ...state.formValues.memberInfo,
        membership: {
          ...state.formValues?.memberInfo?.membership,
          rv: membershipLevel.rv,
          membershipLevel: membershipLevel.level,
        },
      },
    },
  })),
  on(JoinActions.changedQueryPromoCode, (state, { promoCode }) => ({
    ...state,
    formValues: {
      ...state.formValues,
      memberInfo: {
        ...state.formValues.memberInfo,
        membership: {
          ...state.formValues?.memberInfo?.membership,
          promoCode: promoCode || '',
        },
      },
    },
  })),
  on(JoinActions.changedQueryCampaignId, (state, { campaignId }) => ({
    ...state,
    formValues: {
      ...state.formValues,
      memberInfo: {
        ...state.formValues.memberInfo,
        membership: {
          ...state.formValues?.memberInfo?.membership,
          campaignId: campaignId || '',
        },
      },
    },
  })),
  on(JoinActions.changedQueryCouponCode, (state, { couponCode, programCode }) => ({
    ...state,
    formValues: {
      ...state.formValues,
      memberInfo: {
        ...state.formValues.memberInfo,
        membership: {
          ...state.formValues?.memberInfo?.membership,
          couponCode: couponCode || '',
          programCode: programCode || '',
        },
      },
    },
  })),
  on(JoinActions.changedForm, (state, { values }) => {
    let newBillingTo = { ...values?.billing?.billingTo };

    if (values?.billing?.sameBillingAddress) {
      if (state.page === 'join') {
        newBillingTo = {
          address1: values.memberInfo?.account?.address1 || '',
          address2: values.memberInfo?.account?.address2 || '',
          city: values.memberInfo?.account?.city || '',
          lastName: values.memberInfo?.account?.lastName || '',
          firstName: values.memberInfo?.account?.firstName || '',
          state: values.memberInfo?.account?.state || '',
          zipcode: values.memberInfo?.account?.zipcode || '',
        };
      }

      if (state.page === 'gift') {
        newBillingTo = {
          address1: values.giftInfo?.account?.address1 || '',
          address2: values.giftInfo?.account?.address2 || '',
          city: values.giftInfo?.account?.city || '',
          lastName: values.giftInfo?.account?.lastName || '',
          firstName: values.giftInfo?.account?.firstName || '',
          state: values.giftInfo?.account?.state || '',
          zipcode: values.giftInfo?.account?.zipcode || '',
        };
      }
    }

    return {
      ...state,
      formValues: {
        ...values,
        billing: {
          ...state.formValues.billing,
          ...values?.billing,
          billingTo: newBillingTo,
        },
      },
    };
  }),
  on(JoinActions.recostValidate, JoinActions.updateRecostValidate, JoinActions.validatePromoCode, (state) => ({
    ...state,
    validationError: null,
    validationRequestStatus: RequestStatus.RUNNING,
  })),
  on(JoinActions.retryRecostValidate, (state) => ({
    ...state,
    validationRequestStatus: RequestStatus.RUNNING,
  })),
  on(JoinActions.recostValidateFailed, (state, { error }) => ({
    ...state,
    executionData: '',
    validationError: error,
    validationRequestStatus: RequestStatus.FAILED,
  })),
  on(JoinActions.recostValidateSucceeded, (state, { executionData }) => ({
    ...state,
    executionData,
    validationRequestStatus: RequestStatus.SUCCESS,
  })),
  on(JoinActions.pay, (state) => ({
    ...state,
    executionRequestStatus: RequestStatus.RUNNING,
    executionError: null,
  })),
  on(JoinActions.payFailed, (state, { error }) => ({
    ...state,
    executionRequestStatus: RequestStatus.FAILED,
    executionError: error,
  })),
  on(JoinActions.paySucceeded, (state) => ({ ...state, executionRequestStatus: RequestStatus.SUCCESS })),
  on(JoinActions.setTotalCost, (state, { totalCost }) => ({ ...state, totalCost })),
  on(JoinActions.setConfirmedMembers, (state, { members }) => ({ ...state, members })),
  on(JoinActions.resetConfirmedMembers, (state) => ({ ...state, members: [] })),
  on(JoinActions.setPage, (state, { page }) => ({ ...state, page })),
  on(JoinActions.resetError, (state) => ({
    ...state,
    executionError: initialStateJoinState.executionError,
    validationError: initialStateJoinState.validationError,
  })),
  on(JoinActions.resetForm, (state) => ({
    ...state,
    formValues: {
      ...initialStateJoinState.formValues,
      memberInfo: {
        ...initialStateJoinState.formValues.memberInfo,
        membership: {
          ...initialStateJoinState.formValues.memberInfo?.membership,
          ...state.formValues.memberInfo?.membership,
        },
      },
    },
    validationRequestStatus: initialStateJoinState.validationRequestStatus,
    executionData: initialStateJoinState.executionData,
    executionRequestStatus: initialStateJoinState.executionRequestStatus,
    totalCost: initialStateJoinState.totalCost,
  })),
);
